import React, { FunctionComponent } from "react";
import config from "./CookieConsent.config";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/dist/cookieconsent";
type Props = {};

const CookieConsent: FunctionComponent<Props> = (props) => {
  const onFirstAction = React.useCallback(() => {
    if (!window) {
      return;
    }
    // @ts-ignore
    window.dataLayer = window.dataLayer ?? [];
    // @ts-ignore
    window.dataLayer.push({ event: "consentUpdated" });
  }, []);
  const onAccept = React.useCallback(() => {}, []);
  const onChange = React.useCallback(() => {
    if (!window) {
      return;
    }
    // @ts-ignore
    window.dataLayer = window.dataLayer ?? [];
    // @ts-ignore
    window.dataLayer.push({ event: "consentUpdated" });
  }, []);
  React.useEffect(() => {
    if (!window || !window.initCookieConsent) {
      return;
    }
    const cc = window.initCookieConsent();

    cc.run({
      ...config,
      onChange,
      onAccept,
      onFirstAction,
    });

    const openCookieButton = document.getElementById("cc-settings");
    if (openCookieButton) {
      openCookieButton.addEventListener("click", () => {
        cc.showSettings(0);
      });
    }
  }, []);
  return <></>;
};

export default CookieConsent;
