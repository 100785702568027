import React, { FunctionComponent, ReactElement } from "react";
import GlobalStyle from "../styles/GlobalStyle";
import Seo from "../components/Seo/Seo";
import CookieConsent from "../CookieConsentReact";

interface IMainLayout {
  children: ReactElement | ReactElement[];
}

const MainLayout: FunctionComponent<IMainLayout> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Seo />
      <div>{children}</div>
      <CookieConsent />
    </>
  );
};

export default MainLayout;
