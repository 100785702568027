const Variables = {
  colors: {
    black: "#191B1F",
    white: "#ffffff",
    gray: "#ECECEC",
    darkGray: "#949494",
    teal: "#08ECB1",
    purple: "#6826BB",
    purpleDark: "#5D22A8",
  },
  fonts: {
    main: "VELOSans, sans-serif",
    second: "",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  transition: "all .35s ease-in-out",
  maxWidth: "1920px",
  padding: {
    sm: "2rem",
    md: "4rem",
    lg: "7rem",
    xl: "9.4rem",
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1440px",
    ultra: "1920px",
  },
};

export default Variables;
