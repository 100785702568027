// do zaimportowania w stylach globalnych projektu

import { css } from "styled-components";
import Variables from "../styles/Variables";

export const cookiesGlobalStyles = css`
  .c_darkmode {
    --cc-btn-primary-bg: #4e1d8c;
    --cc-btn-primary-hover-bg: #3e1770;
    --cc-btn-primary-text: #ffffff;
    --cc-btn-secondary-text: #ffffff;
    --cc-text: #ffffff;
    --cc-block-text: #ffffff;
    --cc-toggle-bg-off: #b6b6b6;
    --cc-toggle-bg-on: #9567cf;
    --cc-z-index: 2000;
  }

  #cm.cloud {
    max-width: 35vw;
  }

  @media only screen and (max-width: 768px) {
    #cm.cloud {
      max-width: 95vw;
    }
  }

  .cc_div {
    font-family: ${Variables.fonts.main};
  }

  .cc_div .cloud #c-txt {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 768px) {
    .cc_div .cloud #c-txt {
      font-size: 1.2rem;
    }
  }

  .cloud #c-ttl {
    font-weight: 700;
  }

  .cc_div .cloud #c-inr {
    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: center;
  }

  .cc_div .cloud #c-inr #c-inr-i {
    width: 100%;
    padding-right: 0;
  }

  .cc_div .cloud #c-inr #c-inr-i #c-txt {
    display: flex;
    flex-flow: column;
  }

  .cc_div .cloud #c-inr #c-inr-i #c-txt button {
    width: 100%;
    text-align: center;
    margin: 3rem 0 1rem;
    padding: 1.5rem;
    font-size: 1.6rem;
    border: 1px solid white;
    transition: all 0.3s ease-in-out;
    transition-property: color, border-color;
  }

  .cc_div .cloud #c-inr #c-inr-i #c-txt button:hover {
    color: #9567cf;
    border: 1px solid #9567cf;
  }

  .cc_div .cloud #c-bns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }

  .cc_div .cloud #c-bns #c-s-bn {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .cc_div .cloud #c-bns {
      margin-top: 2rem;
      grid-template-columns: 1fr;
    }
  }

  .cc_div .cloud #c-bns #c-s-bn {
    margin-top: 0;
    border: none;
  }

  .cc_div .off-i {
    &:before,
    &:after {
      background-color: #000000;
    }
  }

  .cc_div .cc-link {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }

  .cc_div .cc-link:hover {
    color: #d9c9ee;
    border-bottom: 1px solid #d9c9ee;
  }
`;
