import { createGlobalStyle } from "styled-components";
import Variables from "./Variables";
import { cookiesGlobalStyles } from "../CookieConsentReact/CookieConsent.styled";
import VELOSansBoldWoff from "../fonts/VELOSans-Bold.woff";
import VELOSansBoldWoff2 from "../fonts/VELOSans-Bold.woff2";
import VELOSansMediumWoff from "../fonts/VELOSans-Medium.woff";
import VELOSansMediumWoff2 from "../fonts/VELOSans-Medium.woff2";
import VELOSansRegularWoff from "../fonts/VELOSans-Regular.woff";
import VELOSansRegularWoff2 from "../fonts/VELOSans-Regular.woff2";

const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  ${cookiesGlobalStyles};

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font: inherit;
  }

  select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input {
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${Variables.colors.black} inset !important;
    -webkit-text-fill-color: ${Variables.colors.white} !important;
    animation: onAutoFillStart 5s ease-in-out;
  }
  
  @keyframes onAutoFillStart {
    from {
      background-color: #fff;
    }
    to {
      background-color: #fff;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  html {
    scroll-behavior: smooth;
    font-size: 8px;
  }

  body {
    position: relative;
    font-family: ${Variables.fonts.main};
    font-weight: ${Variables.fontWeight.regular};
    color: ${Variables.colors.purple};
    letter-spacing: 0.04em;
    line-height: 1.5;
    font-style: normal;
    font-size: 1.6rem;
    background-color: #e0e0e0;

    * {
      box-sizing: border-box;
    }
    
    main {
      overflow-x: clip;
    }
    
    strong {
      font-weight: ${Variables.fontWeight.bold};
    }
    
    button {
      letter-spacing: 0.04em;
    }
    
    a {
      cursor: pointer;
      color: inherit;
      text-decoration: none;
      letter-spacing: 0.04em;
      
      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }

    img {
      max-width: 100%;
    }
  }

  @font-face {
    font-family: 'VELOSans';
    src: url(${VELOSansRegularWoff2}) format('woff2'),
    url(${VELOSansRegularWoff}) format('woff');
    font-weight: ${Variables.fontWeight.regular};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VELOSans';
    src: url(${VELOSansMediumWoff2}) format('woff2'),
    url(${VELOSansMediumWoff}) format('woff');
    font-weight: ${Variables.fontWeight.medium};
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'VELOSans';
    src: url(${VELOSansBoldWoff2}) format('woff2'),
    url(${VELOSansBoldWoff}) format('woff');
    font-weight: ${Variables.fontWeight.bold};
    font-style: normal;
    font-display: swap;
  }
  
`;

export default GlobalStyle;
