import UserConfig from "vanilla-cookieconsent";

const cookieConsentConfig: UserConfig = {
  current_lang: "pl",
  autoclear_cookies: true,
  page_scripts: true,
  languages: {
    pl: {
      consent_modal: {
        title: "Nasz serwis wykorzystuje pliki cookie",
        description:
          'Ta strona używa niezbędnych plików cookie, aby zapewnić jej prawidłowe działanie oraz śledzących plików cookie, aby zrozumieć, w jaki sposób wchodzisz z nią w interakcję. Te ostatnie zostaną ustawione dopiero po wyrażeniu zgody. <button type="button" data-cc="c-settings" class="cc-link">Pozwól mi wybrać.</button>',
        primary_btn: {
          text: "Akceptuj wszystkie",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Akceptuj tylko niezbędne",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Preferencje plików cookie",
        save_settings_btn: "Zapisz ustawienia",
        accept_all_btn: "Akceptuj wszystkie",
        reject_all_btn: "Odrzuć wszystkie",
        close_btn_label: "Zamknij",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Date" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "Wykorzystanie plików cookie",
            description:
              'Używamy plików cookie, aby zapewnić podstawowe funkcje witryny i poprawić komfort korzystania z Internetu. W dowolnym momencie możesz wyrazić zgodę lub nie, dla każdej kategorii. Aby uzyskać więcej informacji dotyczących plików cookie i innych danych wrażliwych, zapoznaj się z pełną <a href="https://www.velo.com/pl/pl/polityka-prywatnosci" target="_blank" class="cc-link">polityką prywatności</a>.',
          },
          {
            title: "Wymagane pliki cookie",
            description:
              "Pliki cookie niezbędne ze względów technicznych służą do technicznego działania strony internetowej i zapewnienia jej funkcjonalności. Ich wykorzystanie jest oparte na naszym uzasadnionym interesie, aby zapewnić technicznie bezbłędną stronę internetową. Możesz jednak zasadniczo wyłączyć obsługę plików cookie w swojej przeglądarce.",
            toggle: {
              value: "ad_storage",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Statystyczne pliki cookie",
            description:
              "Statystyczne pliki cookie zbierają informacje o sposobie korzystania ze stron internetowych, aby poprawić ich atrakcyjność, zawartość i funkcjonalność. Ich wykorzystanie odbywa się tylko za Twoją zgodą i tylko wtedy, gdy nie wyłączysz odpowiedniego pliku cookie.",
            toggle: {
              value: "analytics_storage",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "Funkcjonalne pliki cookie",
            description:
              "Te pliki cookie umożliwiają stronie internetowej zapewnienie większej funkcjonalności i personalizacji. Mogą być one ustawione przez nas lub przez zewnętrznych dostawców, których usługi zostały dodane do naszych stron internetowych. Jeśli użytkownik nie zezwoli na stosowanie tych plików cookie, niektóre lub wszystkie te usługi mogą nie działać prawidłowo.",
            toggle: {
              value: "functionality_storage",
              enabled: false,
              readonly: false,
            },
          },
          {
            title: "Pliki cookie związane z reklamami",
            description:
              "Te pliki cookie mogą być ustawiane przez naszych partnerów reklamowych za pośrednictwem naszej strony internetowej. Mogą one być wykorzystywane przez te firmy do budowania profilu zainteresowań użytkownika i wyświetlania odpowiednich reklam na innych stronach. Nie przechowują one bezpośrednio danych osobowych, lecz opierają się na jednoznacznej identyfikacji przeglądarki i sprzętu internetowego. Jeśli użytkownik nie zezwoli na stosowanie tych plików cookie, doświadcza mniej ukierunkowanych reklam.",
            toggle: {
              value: "personalization_storage",
              enabled: false,
              readonly: false,
            },
          },
        ],
      },
    },
  },
  gui_options: {
    consent_modal: {
      layout: "cloud",
      position: "middle center",
      transition: "slide",
      swap_buttons: false,
    },
    settings_modal: {
      layout: "box",
      position: "left",
      transition: "slide",
    },
  },
};

export default cookieConsentConfig;
