import React, { FunctionComponent } from "react";
// @ts-ignore
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import OgImage from "../../images/ogimage.jpg";

const Seo: FunctionComponent = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  return (
    <Helmet
      htmlAttributes={{
        lang: "pl",
      }}
      titleTemplate={site.siteMetadata.title}
      title={site.siteMetadata.title}
    >
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="og:title" content={site.siteMetadata.title} />
      <meta name="og:description" content={site.siteMetadata.description} />
      <meta name="og:type" content={"website"} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta
        data-hid="og:image:type"
        property="og:image:type"
        content="image/jpg"
      />
      <meta
        property="og:image"
        content={`${site.siteMetadata.siteUrl}${OgImage}`}
      />
      <meta property="og:image:alt" content={site.siteMetadata.title} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
    </Helmet>
  );
};

export default Seo;
